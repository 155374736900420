import { FormTenantPromotionCodeSelect } from "@components/form/fields/FormTenantPromotionCodeSelect";
import { FunctionComponent } from "react";

import { Grid, Heading, Stack, Text } from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";

import { getCurrentPeriod } from "../utils";
import { LatestInvoiceLink } from "./LatestInvoiceLink";
import { RestoreLink } from "./RestoreLink";
import { SubscriptionLink } from "./SubscriptionLink";
import { SubscriptionStatusBadge } from "./SubscriptionStatusBadge";
import { CustomerProductsSubscription } from "@libs/api/gateways/plt/plt-gateway.dtos";

interface SubscriptionDetailsProps {
  subscription: CustomerProductsSubscription | undefined;
  tenantId: string;
  customerTenantId?: string;
}
export const SubscriptionDetails: FunctionComponent<SubscriptionDetailsProps> = ({
  subscription,
  tenantId,
  customerTenantId
}) => {
  return (
    <Stack tokens={{ childrenGap: 16 }}>
      {subscription?.description && (
        <Heading variant="section-sub-heading">
          {subscription.description}
        </Heading>
      )}
      <Grid
        templateColumns="200px 300px 400px"
        childrenTokens={{ columnGap: 24, rowGap: 12 }}
      >
        {subscription?.subscriptionId && (
          <>
            <Stack horizontal tokens={{ childrenGap: 16 }}>
              <Text bold>Created:</Text>
              <Text>
                {subscription.createdDate
                  ? DateTime.fromISO(subscription.createdDate).toFormat(
                      DATE_FORMATS.DAY_DEFAULT_FORMAT
                    )
                  : undefined}
              </Text>
            </Stack>

            <Stack horizontal tokens={{ childrenGap: 16 }}>
              <Text bold>Current Period:</Text>
              <Text>{getCurrentPeriod(subscription)}</Text>
            </Stack>

            <Stack horizontal tokens={{ childrenGap: 16 }}>
              <Text bold>Manage Subscription:</Text>
              <SubscriptionLink subscriptionId={subscription.subscriptionId} />
            </Stack>

            <Stack horizontal tokens={{ childrenGap: 16 }}>
              <Text bold>Schedule:</Text>
              <Text styles={{ root: { textTransform: "capitalize" } }}>
                {subscription.schedule}
              </Text>
            </Stack>

            <Stack horizontal tokens={{ childrenGap: 16 }}>
              <Text bold>Status:</Text>
              <SubscriptionStatusBadge item={subscription} horizontal />
            </Stack>

            <Stack horizontal tokens={{ childrenGap: 16 }}>
              <Text bold>Latest Invoice:</Text>
              <LatestInvoiceLink
                latestInvoiceId={subscription.latestInvoiceId}
              />
            </Stack>

            <RestoreLink
              subscription={subscription}
              customerTenantId={customerTenantId}
            />
          </>
        )}
      </Grid>
      <Stack tokens={{ childrenGap: 8 }}>
        <Text bold>
          Promotion codes: {subscription?.promotionCodeList?.join(", ")}
        </Text>
        <FormTenantPromotionCodeSelect
          tenantId={tenantId}
          excludes={subscription?.promotionCodeList}
          name="additionalPromotionCodeList"
          label="Add additional promotion codes"
          styles={{ root: { maxWidth: 500 } }}
        />
      </Stack>
    </Stack>
  );
};
