import { FunctionComponent, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { Grid, Heading, Separator, Stack } from "@bps/fluent-ui";
import { isDefined } from "@bps/utils";
import { CopyableText } from "@components/CopyableText";
import { FormBusinessRolesSelect } from "@components/form/fields/FormBusinessRolesSelect";
import { FormButtonsGroupSingleChoice } from "@components/form/fields/FormButtonsGroupSingleChoice";
import { FormSecurityRolesSelect } from "@components/form/fields/FormSecurityRolesSelect";
import { FormSpinNumberInput } from "@components/form/fields/FormSpinNumberInput";
import { FormTextField } from "@components/form/fields/FormTextField";
import { Form } from "@components/form/Form";
import { SubmitFormButtons } from "@components/form/SubmitFormButtons";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import {
  PltUser,
  SecurityRolesAndPermissionsDto,
  UserDefaultAuthenticationDto
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  PltCacheKeys,
  useAddUserDefaultAuth,
  useUpdateUser,
  useUpdateUserDefaultAuth
} from "@libs/api/gateways/plt/plt-gateway.hooks";
import { Permissions } from "@libs/permissions/permissions.enum";

import { IpRangeFormSection } from "../../_shared-blocks";
import { HINT, options, Options } from "./PltUserAuthenticationForm.types";
import {
  getUserAuthDtoPayload,
  getUserDtoPayload,
  hasDirtyFieldsKeys
} from "./user-form.utils";
import { UserFormProps, UserFormValues } from "./UserForm.types";
import { getDefaultOption } from "./utils";

export const UserForm: FunctionComponent<UserFormProps> = ({
  user,
  tenantAuth,
  userAuth,
  application
}) => {
  const {
    mutateAsync: addUserAuth,
    error: addUserAuthError
  } = useAddUserDefaultAuth();

  const {
    mutateAsync: updateUserAuth,
    error: updateUserAuthError
  } = useUpdateUserDefaultAuth();

  const { mutateAsync: updateUser, error: updateUserError } = useUpdateUser();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { tenantId } = useParams<{ tenantId: string }>();

  const { data: hasWritePermission } = usePermissionsCheckQuery(
    Permissions.PltCatalogUserWrite
  );

  const disableAccess = user.tenantId !== tenantId || !hasWritePermission;

  const defaultValues: UserFormValues = useMemo(() => {
    return {
      firstName: user?.firstName,
      lastName: user?.lastName,
      isInactive:
        typeof user?.isInactive === "undefined" ? false : user.isInactive,
      status: user?.status,
      deviceMfaMaxDuration: userAuth?.deviceMfaMaxDuration
        ? userAuth.deviceMfaMaxDuration.toString()
        : "",
      sessionMaxDuration: userAuth?.sessionMaxDuration
        ? userAuth.sessionMaxDuration.toString()
        : "",
      quickPinRequired: getDefaultOption(userAuth?.quickPinRequired),
      longPasswordRequired: getDefaultOption(userAuth?.longPasswordRequired),
      requireAlternateEmailAuthenticationMethod: getDefaultOption(
        userAuth?.requireAlternateEmailAuthenticationMethod
      ),
      requireAlternateEmailAuthenticationStrength: getDefaultOption(
        userAuth?.requireAlternateEmailAuthenticationStrength
      ),
      requirePhoneAuthenticationMethod: getDefaultOption(
        userAuth?.requirePhoneAuthenticationMethod
      ),
      requirePhoneAuthenticationStrength: getDefaultOption(
        userAuth?.requirePhoneAuthenticationStrength
      ),
      requireTotpAuthenticationMethod: getDefaultOption(
        userAuth?.requireTotpAuthenticationMethod
      ),
      requireTotpAuthenticationStrength: getDefaultOption(
        userAuth?.requireTotpAuthenticationStrength
      ),
      businessRoles: user.businessRoles,
      securityRoles: (user.securityRoles ?? [])
        .map(role => role.code)
        .filter(isDefined),
      ipRanges: userAuth?.ipRanges ?? []
    };
  }, [user, userAuth]);

  return (
    <Form<UserFormValues>
      defaultValues={defaultValues}
      onSubmit={async (values, form) => {
        const { securityRoles = [] } =
          queryClient.getQueryData<SecurityRolesAndPermissionsDto>([
            PltCacheKeys.SecurityRolesAndPermissions
          ]) ?? {};

        const userPayload: PltUser = getUserDtoPayload({
          user,
          securityRoles,
          values
        });

        const userAuthPayload: UserDefaultAuthenticationDto = getUserAuthDtoPayload(
          { userId: user.id, tenantId: tenantId!, values }
        );

        const hasUserDirtyFields = hasDirtyFieldsKeys(
          form.formState.dirtyFields,
          userPayload
        );

        const hasUserAuthDirtyFields = hasDirtyFieldsKeys(
          form.formState.dirtyFields,
          userAuthPayload
        );

        if (hasUserDirtyFields) {
          await updateUser({ ...userPayload, ...userAuthPayload });
        }
        if (hasUserAuthDirtyFields) {
          if (userAuth) {
            await updateUserAuth(userAuthPayload);
          } else {
            await addUserAuth(userAuthPayload);
          }
        }
        form.reset(values);
      }}
      error={updateUserError || addUserAuthError || updateUserAuthError}
      hideButtons
    >
      <Separator />
      <Stack tokens={{ maxWidth: 1154, childrenGap: 16 }}>
        <SubmitFormButtons
          hideSubmitButton={disableAccess}
          onCancel={() => {
            navigate(-1);
          }}
        />
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <Grid
            templateColumns="repeat(2, 320px)"
            childrenTokens={{ rowGap: 8, columnGap: 16 }}
          >
            <FormTextField
              name="firstName"
              label="First name"
              disabled={disableAccess}
            />
            <FormTextField
              name="lastName"
              label="Last name"
              disabled={disableAccess}
            />
            <FormButtonsGroupSingleChoice
              label="Status (Prem Site)"
              name="status"
              options={[
                {
                  key: "ACTIVE",
                  text: "Enabled",
                  disabled: true
                },
                {
                  key: "PENDINGSIGNUP",
                  text: "Pending SignUp",
                  disabled: true
                },
                {
                  key: "INACTIVE",
                  text: "Disabled",
                  disabled: true
                }
              ]}
            />
            <FormButtonsGroupSingleChoice
              label="Status"
              name="isInactive"
              options={[
                {
                  key: false,
                  text: "Enabled",
                  disabled: disableAccess
                },
                {
                  key: true,
                  text: "Disabled",
                  disabled: disableAccess
                }
              ]}
            />
            <FormBusinessRolesSelect
              name="businessRoles"
              application={application}
              label="Business roles"
              disabled={disableAccess}
            />
            <FormSecurityRolesSelect
              name="securityRoles"
              application={application}
              label="Security roles"
              disabled={disableAccess}
            />
            <Stack>
              <Heading labelPaddings>External Id</Heading>
              {user?.externalId ? (
                <CopyableText clipboardContent={user?.externalId.toString()}>
                  {user?.externalId.toString()}
                </CopyableText>
              ) : (
                "-"
              )}
            </Stack>
            <Stack />
            <FormButtonsGroupSingleChoice
              label="User long password"
              name="longPasswordRequired"
              options={options
                .map(opt => {
                  return { disabled: disableAccess, ...opt };
                })
                .filter(
                  opt =>
                    !(
                      typeof tenantAuth?.longPasswordRequired === "undefined" &&
                      opt.key === Options.default
                    )
                )}
              hint={
                typeof tenantAuth?.longPasswordRequired !== "undefined"
                  ? `${HINT} ${tenantAuth.longPasswordRequired ? "on" : "off"}`
                  : undefined
              }
              fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
            />
            <Stack />
            <FormButtonsGroupSingleChoice
              label="User quick PIN"
              name="quickPinRequired"
              options={options
                .map(opt => {
                  return { disabled: disableAccess, ...opt };
                })
                .filter(
                  opt =>
                    !(
                      typeof tenantAuth?.quickPinRequired === "undefined" &&
                      opt.key === Options.default
                    )
                )}
              hint={
                typeof tenantAuth?.quickPinRequired !== "undefined"
                  ? `${HINT} ${tenantAuth.quickPinRequired ? "on" : "off"}`
                  : undefined
              }
              fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
            />
            <FormSpinNumberInput
              label="Pin Lifetime (days)"
              name="sessionMaxDuration"
              min={1}
              hint={
                tenantAuth?.sessionMaxDuration
                  ? `${HINT} ${tenantAuth.sessionMaxDuration}`
                  : undefined
              }
              disabled={disableAccess}
            />
            <FormButtonsGroupSingleChoice
              label="Setup Alternate Email MFA"
              name="requireAlternateEmailAuthenticationMethod"
              options={options
                .map(opt => {
                  return { disabled: disableAccess, ...opt };
                })
                .filter(
                  opt =>
                    !(
                      typeof tenantAuth?.requireAlternateEmailAuthenticationMethod ===
                        "undefined" && opt.key === Options.default
                    )
                )}
              hint={
                typeof tenantAuth?.requireAlternateEmailAuthenticationMethod !==
                "undefined"
                  ? `${HINT} ${
                      tenantAuth.requireAlternateEmailAuthenticationMethod
                        ? "on"
                        : "off"
                    }`
                  : undefined
              }
              fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
            />
            <FormButtonsGroupSingleChoice
              label="Enable Alternate Email MFA during login"
              name="requireAlternateEmailAuthenticationStrength"
              options={options
                .map(opt => {
                  return { disabled: disableAccess, ...opt };
                })
                .filter(
                  opt =>
                    !(
                      typeof tenantAuth?.requireAlternateEmailAuthenticationStrength ===
                        "undefined" && opt.key === Options.default
                    )
                )}
              hint={
                typeof tenantAuth?.requireAlternateEmailAuthenticationStrength !==
                "undefined"
                  ? `${HINT} ${
                      tenantAuth.requireAlternateEmailAuthenticationStrength
                        ? "on"
                        : "off"
                    }`
                  : undefined
              }
              fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
            />
            <FormButtonsGroupSingleChoice
              label="Setup Mobile Phone MFA"
              name="requirePhoneAuthenticationMethod"
              options={options
                .map(opt => {
                  return { disabled: disableAccess, ...opt };
                })
                .filter(
                  opt =>
                    !(
                      typeof tenantAuth?.requirePhoneAuthenticationMethod ===
                        "undefined" && opt.key === Options.default
                    )
                )}
              hint={
                typeof tenantAuth?.requirePhoneAuthenticationMethod !==
                "undefined"
                  ? `${HINT} ${
                      tenantAuth.requirePhoneAuthenticationMethod ? "on" : "off"
                    }`
                  : undefined
              }
              fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
            />
            <FormButtonsGroupSingleChoice
              label="Enable Mobile Phone MFA during login"
              name="requirePhoneAuthenticationStrength"
              options={options
                .map(opt => {
                  return { disabled: disableAccess, ...opt };
                })
                .filter(
                  opt =>
                    !(
                      typeof tenantAuth?.requirePhoneAuthenticationStrength ===
                        "undefined" && opt.key === Options.default
                    )
                )}
              hint={
                typeof tenantAuth?.requirePhoneAuthenticationStrength !==
                "undefined"
                  ? `${HINT} ${
                      tenantAuth.requirePhoneAuthenticationStrength
                        ? "on"
                        : "off"
                    }`
                  : undefined
              }
              fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
            />
            <FormButtonsGroupSingleChoice
              label="Setup Time based One Time Password (TOTP) MFA"
              name="requireTotpAuthenticationMethod"
              options={options
                .map(opt => {
                  return { disabled: true, ...opt };
                })
                .filter(
                  opt =>
                    !(
                      typeof tenantAuth?.requireTotpAuthenticationMethod ===
                        "undefined" && opt.key === Options.default
                    )
                )}
              hint={
                typeof tenantAuth?.requireTotpAuthenticationMethod !==
                "undefined"
                  ? `${HINT} ${
                      tenantAuth.requireTotpAuthenticationMethod ? "on" : "off"
                    }`
                  : undefined
              }
              fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
            />
            <FormButtonsGroupSingleChoice
              label="Enable Time based One Time Password (TOTP) MFA during login"
              name="requireTotpAuthenticationStrength"
              options={options
                .map(opt => {
                  return { disabled: true, ...opt };
                })
                .filter(
                  opt =>
                    !(
                      typeof tenantAuth?.requireTotpAuthenticationStrength ===
                        "undefined" && opt.key === Options.default
                    )
                )}
              hint={
                typeof tenantAuth?.requireTotpAuthenticationStrength !==
                "undefined"
                  ? `${HINT} ${
                      tenantAuth.requireTotpAuthenticationStrength
                        ? "on"
                        : "off"
                    }`
                  : undefined
              }
              fieldItemStyles={{ root: { flexGrow: 1, flexBasis: 0 } }}
            />
            <FormSpinNumberInput
              label="Device MFA max duration (min) (NOT USED)"
              name="deviceMfaMaxDuration"
              min={1}
              hint={
                tenantAuth?.deviceMfaMaxDuration
                  ? `${HINT} ${tenantAuth.deviceMfaMaxDuration}`
                  : undefined
              }
              disabled={disableAccess}
            />
          </Grid>
          <Separator vertical />
          <Stack.Item grow={1}>
            <IpRangeFormSection hasWritePermission={!disableAccess} />
          </Stack.Item>
        </Stack>
      </Stack>
    </Form>
  );
};
