import { FormDatePicker } from "@components/form/fields/FormDatePicker";
import { FormSwitch } from "@components/form/fields/FormSwitch";
import { FormTextField } from "@components/form/fields/FormTextField";
import { FormTimePicker } from "@components/form/fields/FormTimePicker";
import { IsDevelopmentEnvironment } from "@libs/config/config";
import { useCallback, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import {
  Heading,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";

import { hasRolloutOccurred } from "../utils";
import {
  isValidRolloutDateAndTime,
  rolloutFormSchema,
  rolloutFormValidator
} from "./RolloutFormValidator";
import { Rollout } from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { validationResolver } from "@components/form/validation/validation-resolver";

export interface RolloutFormValues {
  displayName?: string;
  isActive?: boolean;
  initialDownloadTimeStartUtc?: string;
  initialDownloadTimeEndUtc?: string;
  initialInstallTimeStartUtc?: string;
  initialInstallTimeEndUtc?: string;
  initialDownloadDateStartUtc?: Date;
  initialDownloadDateEndUtc?: Date;
  initialInstallDateStartUtc?: Date;
  initialInstallDateEndUtc?: Date;
}

interface RolloutFormProps {
  rollout: Rollout;
  onSubmit: (values: RolloutFormValues) => void;
}

const parseRollout = (rollout?: Rollout): RolloutFormValues => {
  return {
    ...rollout,
    initialDownloadTimeStartUtc: rollout?.initialDownloadStartUtc?.toTimeInputFormat(),
    initialDownloadTimeEndUtc: rollout?.initialDownloadEndUtc?.toTimeInputFormat(),
    initialInstallTimeStartUtc: rollout?.initialInstallStartUtc?.toTimeInputFormat(),
    initialInstallTimeEndUtc: rollout?.initialInstallEndUtc?.toTimeInputFormat(),
    initialDownloadDateStartUtc: rollout?.initialDownloadStartUtc?.toJSDate(),
    initialDownloadDateEndUtc: rollout?.initialDownloadEndUtc?.toJSDate(),
    initialInstallDateStartUtc: rollout?.initialInstallStartUtc?.toJSDate(),
    initialInstallDateEndUtc: rollout?.initialInstallEndUtc?.toJSDate()
  };
};

export const RolloutForm: React.FC<RolloutFormProps> = ({
  rollout,
  onSubmit
}) => {
  const [validationError, setValidationError] = useState<string | undefined>();

  const theme = useTheme();
  const { control, ...form } = useForm<RolloutFormValues>({
    defaultValues: parseRollout(rollout),
    resolver: values =>
      validationResolver(
        (values: RolloutFormValues) =>
          rolloutFormValidator.validateWithParse(values, rolloutFormSchema),
        values
      )
  });

  const rolloutOccurred =
    hasRolloutOccurred(rollout) && !IsDevelopmentEnvironment;

  const _onSubmit = useCallback<SubmitHandler<RolloutFormValues>>(
    values => {
      const {
        initialDownloadDateStartUtc,
        initialDownloadDateEndUtc,
        initialDownloadTimeStartUtc,
        initialDownloadTimeEndUtc,
        initialInstallDateStartUtc,
        initialInstallDateEndUtc,
        initialInstallTimeStartUtc,
        initialInstallTimeEndUtc
      } = values;

      const error = isValidRolloutDateAndTime({
        downloadStartDate: initialDownloadDateStartUtc,
        downloadStartTime: initialDownloadTimeStartUtc,
        downloadEndDate: initialDownloadDateEndUtc,
        downloadEndTime: initialDownloadTimeEndUtc,
        installStartDate: initialInstallDateStartUtc,
        installStartTime: initialInstallTimeStartUtc,
        installEndDate: initialInstallDateEndUtc,
        installEndTime: initialInstallTimeEndUtc
      });

      setValidationError(error);

      if (error) return;

      onSubmit({
        ...values
      });
    },
    [onSubmit]
  );

  return (
    <FormProvider {...{ control, ...form }}>
      <Stack
        as="form"
        tokens={{ childrenGap: theme.spacing.m }}
        onSubmit={form.handleSubmit(_onSubmit)}
      >
        {validationError && (
          <MessageBar messageBarType={MessageBarType.error}>
            {validationError}
          </MessageBar>
        )}

        <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
          <FormTextField
            control={control}
            name="displayName"
            label="Display Name"
          />

          <FormSwitch
            name="isActive"
            label="Enabled"
            data-test="switch-isactive"
          />

          <Heading labelPaddings>Initial download window (UTC Time)</Heading>
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <FormDatePicker
              disabled={rolloutOccurred}
              control={control}
              name="initialDownloadDateStartUtc"
            />
            <Text>to</Text>
            <FormDatePicker
              disabled={rolloutOccurred}
              control={control}
              name="initialDownloadDateEndUtc"
            />
          </Stack>
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <FormTimePicker
              disabled={rolloutOccurred}
              control={control}
              name="initialDownloadTimeStartUtc"
            />
            <Text>to</Text>
            <FormTimePicker
              disabled={rolloutOccurred}
              control={control}
              name="initialDownloadTimeEndUtc"
            />
          </Stack>
        </Stack>

        <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
          <Heading labelPaddings>
            Initial installation window (UTC Time)
          </Heading>
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <FormDatePicker
              disabled={rolloutOccurred}
              control={control}
              name="initialInstallDateStartUtc"
              required
            />
            <Text>to</Text>
            <FormDatePicker
              disabled={rolloutOccurred}
              control={control}
              name="initialInstallDateEndUtc"
              required
            />
          </Stack>
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <FormTimePicker
              disabled={rolloutOccurred}
              control={control}
              name="initialInstallTimeStartUtc"
            />
            <Text>to</Text>
            <FormTimePicker
              disabled={rolloutOccurred}
              control={control}
              name="initialInstallTimeEndUtc"
            />
          </Stack>
        </Stack>

        <Stack horizontal>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
