import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router";

import { Form } from "@components/form/Form";
import {
  CustomerProductsDto,
  Tenant
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useAddCustomerSubscriptions } from "@libs/api/gateways/plt/plt-gateway.hooks";

import { SubscriptionEditFormFields } from "./SubscriptionEditFormFields";
import { SubscriptionItemProducts } from "./SubscriptionItems.types";
import { getAddSubscriptionsDtos, getSubscriptionsProducts } from "./utils";

interface SubscriptionFormProps {
  customerProducts: CustomerProductsDto;
  tenant: Tenant;
  id: string | undefined;
}

export interface SubscriptionFormValues {
  subscriptionProducts: SubscriptionItemProducts[];
  promotionCodeList?: string[];
  additionalPromotionCodeList?: string[];
}

export const SubscriptionForm: FunctionComponent<SubscriptionFormProps> = ({
  customerProducts,
  tenant,
  id
}) => {
  const {
    mutateAsync: submitSubscription,
    error
  } = useAddCustomerSubscriptions(tenant.id);

  const navigate = useNavigate();
  const subscription = customerProducts.subscriptionList.find(
    s => s.subscriptionId === id
  );

  const subscriptionProducts = subscription
    ? getSubscriptionsProducts(subscription).filter(s => !!s.quantity)
    : [];

  const handleSubmit = async (values: SubscriptionFormValues) => {
    const payload = getAddSubscriptionsDtos({
      ...values,
      promotionCodeList: values.additionalPromotionCodeList
    });

    await submitSubscription(payload);
    navigate(`../../../${tenant.id}/sales-subscriptions`);
  };

  const onTrySubmit = async (values: SubscriptionFormValues) => {
    await handleSubmit(values);
  };

  return (
    <Form<SubscriptionFormValues>
      error={error}
      defaultValues={{
        subscriptionProducts,
        promotionCodeList: subscription?.promotionCodeList
      }}
      onSubmit={onTrySubmit}
      formStyles={{ root: { height: "100%", flexGrow: 1 } }}
      buttonsRootStyles={{ root: { marginTop: "auto" } }}
      hideButtons
    >
      <SubscriptionEditFormFields subscription={subscription} tenant={tenant} />
    </Form>
  );
};
