import { Form } from "@components/form/Form";
import { FormProductsSelect } from "@components/form/fields/FormProductsSelect";
import React, { FunctionComponent } from "react";

import { DefaultButton, Dialog, Separator } from "@bps/fluent-ui";
import { QueryStateIndicator } from "@components/QueryStateIndicator";
import {
  SalesProductDto,
  Tenant
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useChildTenants } from "@libs/api/gateways/plt/plt-gateway.hooks";

import { SubscriptionItemProducts } from "../subscription-form/SubscriptionItems.types";
import { AddSubscriptionItemDialogValues } from "./AddSubscriptionItemDialog.types";
import { ChildTenantsListField } from "./ChildTenantsListField";
import { Validator } from "@components/form/validation/Validator";
import { ValidationSchema } from "@bps/utils";

interface AddSubscriptionItemDialogProps {
  tenant: Tenant;
  hidden: boolean;
  setHidden: (value: boolean) => void;
  onSubmit: (values: AddSubscriptionItemDialogValues) => Promise<void>;
  selectedProducts: SubscriptionItemProducts[];
}

const validator = new Validator<AddSubscriptionItemDialogValues>();

const schema: ValidationSchema<AddSubscriptionItemDialogValues> = {
  salesProductId: validator.string().required(),
  items: validator.custom().func<"items">(items => {
    if (
      items.length &&
      items.every(i => Number(i.quantity) === 0 || !i.quantity)
    ) {
      return "Add the selected product for at least for one of the child tenants!";
    }
    return items;
  })
};

const AddSubscriptionItemDialogBase: FunctionComponent<Omit<
  AddSubscriptionItemDialogProps,
  "setHidden" | "hidden"
> & {
  onDismiss: () => void;
}> = ({ tenant, onDismiss, onSubmit, selectedProducts }) => {
  const childTenantsQuery = useChildTenants(tenant.id);

  // There are 8 active products in DevA, the list is always showing all items

  // Check the product against the existing list of items
  const productFilter = (product: SalesProductDto): boolean => {
    // If the product is already in the list, filter it out
    if (selectedProducts.some(i => i.salesProductId === product.id))
      return false;

    // If the product has a different interval, filter it out
    if (
      selectedProducts.some(
        i =>
          i.salesProductInterval &&
          product.interval &&
          // PBI#39037 Check should be case insensitive
          i.salesProductInterval.toLowerCase() !==
            product.interval.toLowerCase()
      )
    )
      return false;

    // If the product has a different country, filter it out
    // PBI#39037 Check should be case-insensitive
    return product.country?.toLowerCase() === tenant.country.toLowerCase();
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: "Add subscription item",
        showCloseButton: true,
        onDismiss,
        styles: { content: { minHeight: 381 } }
      }}
      minWidth={450}
    >
      <QueryStateIndicator
        {...childTenantsQuery}
        noDataText={`${tenant.name} has no child tenants!`}
      >
        {childTenants => (
          <Form<AddSubscriptionItemDialogValues>
            onSubmit={onSubmit}
            defaultValues={{
              salesProductId: "",
              items: childTenants.map(c => ({
                childTenantId: c.id,
                quantity: "0"
              }))
            }}
            onCancel={onDismiss}
            validate={values => validator.validateWithParse(values, schema)}
          >
            <FormProductsSelect
              label="Products"
              name="salesProductId"
              onFilterProducts={productFilter}
            />
            <Separator />
            <ChildTenantsListField
              childTenants={childTenants}
              tenant={tenant}
            />
          </Form>
        )}
      </QueryStateIndicator>
    </Dialog>
  );
};

export const AddSubscriptionItemDialog: FunctionComponent<AddSubscriptionItemDialogProps> = ({
  hidden,
  setHidden,
  ...rest
}) => {
  return (
    <>
      {!hidden && (
        <AddSubscriptionItemDialogBase
          {...rest}
          onDismiss={() => setHidden(true)}
        />
      )}
      <DefaultButton
        onClick={() => setHidden(false)}
        iconProps={{ iconName: "Add" }}
      >
        Add new subscription item
      </DefaultButton>
    </>
  );
};
